// Weird {`https://...`} syntax is to avoid a prettier bug:
// https://github.com/prettier/prettier/issues/2347

import React from 'react';
import LegalLayout from '../components/layout/LegalLayout';

const updatedAt = 'May 4, 2023';

const Providers: React.SFC = () => (
  <LegalLayout title="Service Providers List">
    <section>
      <h1>Service Providers List</h1>
      <p>Last updated: {updatedAt}</p>
      <p>
        This Service Provider List will be updated from time-to-time, as Service
        Providers are added and removed. You may subscribe to this{' '}
        <a href="https://slab.us14.list-manage.com/subscribe?u=521a767f37db3b04d7531fbfb&id=533ce1c937">
          mailing list
        </a>{' '}
        to be notified when updates are made to this Service Providers List.
      </p>
    </section>
    <section>
      <h3>Analytics</h3>
      <p>
        We may use third-party Service Providers to monitor and analyze the use
        of our Services.
      </p>
      <h4>Google Analytics</h4>
      <p>
        Google Analytics is a web analytics service offered by Google that
        tracks and reports website traffic. Google uses the data collected to
        track and monitor the use of our Service. This data is shared with other
        Google services. Google may use the collected data to contextualize and
        personalize the ads of its own advertising network. You can opt-out of
        having made your activity on the Service available to Google Analytics
        by installing the Google Analytics opt-out browser add-on. The add-on
        prevents the Google Analytics JavaScript (ga.js, analytics.js, and
        dc.js) from sharing information with Google Analytics about visits
        activity. For more information on the privacy practices of Google,
        please visit the Google Privacy Terms web page:{' '}
        <a href="https://www.google.com/intl/en/policies/privacy">
          https://www.google.com/intl/en/policies/privacy
        </a>
      </p>
      <h4>Amplitude</h4>
      <p>
        Amplitude user analytics is provided by Amplitude, Inc. For more
        information on what type of information Amplitude collects, please
        visit:{' '}
        <a href="https://amplitude.com/privacy">
          https://amplitude.com/privacy
        </a>
      </p>
      <h4>LogRocket</h4>
      <p>
        LogRocket user analytics is provided by LogRocket, Inc. For more
        information on what type of information LogRocket collects, please
        visit:{' '}
        <a href="https://logrocket.com/privacy/">
          https://logrocket.com/privacy/
        </a>
      </p>
      <h4>Segment</h4>
      <p>
        Segment user analytics is provided by Segment.io, Inc. For more
        information on what type of information Segment collects, please visit:{' '}
        <a href="https://segment.com/docs/legal/privacy">
          https://segment.com/docs/legal/privacy
        </a>
      </p>
    </section>
    <section>
      <h3>Payments</h3>
      <p>
        We may provide paid products and/or services within the Services. In
        that case, we use third-party services for payment processing (e.g.
        payment processors).
      </p>
      <p>
        We will not store or collect your payment card details. That information
        is provided directly to our third-party payment processors whose use of
        your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by
        the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information.
      </p>
      <p>The payment processor we work with is:</p>
      <h4>Stripe</h4>
      <p>
        Their Privacy Policy can be viewed at{' '}
        <a href="https://stripe.com/us/privacy">
          https://stripe.com/us/privacy
        </a>
      </p>
    </section>
    <section>
      <h3>Miscellaneous</h3>
      <p>
        We work with other Service Providers to provide a range of services that
        help us provide and improve the Services.
      </p>
      <h4>Algolia</h4>
      <p>
        Algolia&apos;s search infrastructure services are provided by Algolia,
        Inc. For more information on what type of information Algolia collects,
        please visit:{' '}
        <a href="https://www.algolia.com/policies/privacy">
          https://www.algolia.com/policies/privacy
        </a>
      </p>
      <h4>Cloudflare</h4>
      <p>
        We use Cloudflare for distributed denial of service (DDoS) protection.
        Cloudflare&apos;s security services are provided by Cloudflare, Inc. For
        more information on what type of information Cloudflare collects, please
        visit:{' '}
        <a href="https://www.cloudflare.com/security-policy">
          https://www.cloudflare.com/security-policy
        </a>
      </p>
      <h4>Customer.io</h4>
      <p>
        We use Customer.io to send product update newsletters. Customer.io is
        provided by Peaberry Software, Inc. For more information on what type of
        information Customer.io collects, please visit:{' '}
        <a href="https://customer.io/legal/privacy-policy/">
          https://customer.io/legal/privacy-policy/
        </a>
      </p>
      <h4>Google Cloud Platform</h4>
      <p>
        We use Google Cloud Platform (GCP) for web infrastructure. GCP&apos;s
        web infrastructure services are provided by Google. For more information
        on what type of information GCP collects, please visit:{' '}
        <a href="https://cloud.google.com/security/privacy/">
          https://cloud.google.com/security/privacy/
        </a>
      </p>
      <h4>Intercom</h4>
      <p>
        We use the Intercom platform to provide support services. The Intercom
        platform is provided by Intercom, Inc. and its affiliates. For more
        information on what type of information Intercom collects, please visit:{' '}
        <a href="https://www.intercom.com/terms-and-policies#privacy">
          https://www.intercom.com/terms-and-policies#privacy
        </a>
      </p>
      <h4>MailChimp</h4>
      <p>
        We use MailChimp to send and track email newsletters and to collect
        analytics regarding the same. MailChimp&apos;s marketing services are
        provided by The Rocket Science Group LLC d/b/a MailChimp. For more
        information on what type of information MailChimp collects, please
        visit:{' '}
        <a href="https://mailchimp.com/legal/privacy">
          https://mailchimp.com/legal/privacy
        </a>
      </p>
      <h4>OpenAI</h4>
      <p>
        We use OpenAI allow users to enhance their use of Slab with artificial
        intelligence. The OpenAI platform is provided by OpenAI, LLC. For more
        information on what type of information OpenAI collects, please visit:{' '}
        <a href="https://openai.com/policies/privacy-policy">
          https://openai.com/policies/privacy-policy
        </a>
      </p>
      <h4>Postmark</h4>
      <p>
        Postmark&apos;s email infrastructure services are provided by AC PM LLC.
        For more information on what type of information SendGrid collects,
        please visit:{' '}
        <a href="https://postmarkapp.com/privacy-policy">
          https://postmarkapp.com/privacy-policy
        </a>
      </p>
      <h4>Sentry</h4>
      <p>
        We use Sentry for error reporting and debugging services. Sentry is
        provided by Functional Software, Inc. For more information on what type
        of information Sentry collects, please visit:{' '}
        <a href="https://sentry.io/privacy">https://sentry.io/privacy</a>
      </p>
      <h4>Vitally</h4>
      <p>
        We use Vitally to help provide support and feedback services. Vitally is
        provided by Vitally, Inc. For more information on what type of
        information Vitally collects, please visit:{' '}
        <a href="https://vitally.io/legal/privacy">
          https://vitally.io/legal/privacy
        </a>
      </p>
    </section>
  </LegalLayout>
);

export default Providers;
